.whatsapp-icon{
    max-height: 32px;
    margin-left: 10px;
}

.structure-orders-page {
    margin: 0 auto;
    max-width: 1280px;
    padding: 0px 35px;
}


// Search form
.basic-header-logos {
    background-color: white;
    
    img{
        max-width: 200px;
        @media (max-width: 767px) {
            max-width: 120px;
        }
    }

    .img-logo {
        // display: none;
    }

    .bradesco-logo-wrapper{
        display: flex;
        align-items: center;
        justify-content: flex-end;        

        img {
            max-height: 95px;
            height: 70px;
            width: auto;
        }
    }

    margin-top: 16px;
    margin-bottom: 25px;
}

// .search_form-wrapper {
//     display: flex;
//     justify-content: center;
// }

.search_form-wrapper.ant-row{
    width: 100% !important;
    margin: 25px 0 60px 0 !important;

    .card-form.ant-col {
        padding: 25px !important;
        border-radius: 10px;
        border: solid 1px rgb(172, 172, 172);
    }

    .content-img-search {
        width: 100%;
        display: flex;
        justify-content: center;

        img.search-logo {
            max-width: 130px;
            margin-bottom: 35px;   
        }
    }

    // .ant-col {
    //     padding: 0 50px;
    // }

    .content-search {
        display: flex;
        text-align: center;
        justify-content: center;
        flex-direction: column;

        form {
            // padding: 0 80px;

            button {
                width: 120px;
            }
        }
    }

    .subtitle {
        height: 60px;
        font-size: 12px;
    }


    // > .ant-col:last-child:not(:first-child) {
    //     @media (max-width: 767px) {
    //         margin-top: 64px!important;
    //         padding-top: 64px!important;
    //         border-top: 1px solid rgb(195,195,195);
    //     }
    // }
}

.search-form, .login-page {
    
    .inquest-button {
        border-radius: 30px!important;
        background-color: rgb(0,145,226);
        height: 40px;
        max-width: 150px;

        :disabled {
            background-color: #676767 !important;
        }
    }
    
    .inquest-input{
        border-color: black;
        border-width: 2px;
        height: 40px;
    }
}

.radio-buttons-case-type.ant-row {
    .ant-col {
        text-align: left !important;
    }
}



// init login page
.login-page{
    .logo-bradesco{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-bottom: 64px!important;

        img {
            position: relative;
            top: 20px;
            width: 230px;
        }
        
    }
    .form-wrapper{
        border: 1px solid gray;
        border-radius: 10px;
        padding: 25px;
        background-color: rgb(250, 250, 250);
        max-width: 400px;
        text-align: left;
    }

    .login-form-actions {
        width: 100%;
        text-align: center;
    }

    .inquest-logo{
        img {
            width: 250px;
        }
        text-align: center;
        margin-bottom: 25px;
    }

    .remember-me-div{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}

.inquest-link{
    text-decoration: none!important;
    color: rgb(0,145,226);
    font-weight: bold;
}

.report{
    .logos{
        img{
            max-height: 60px!important;

            @media (max-width: 767px) {
                max-height: 50px!important;
            }
        }
    }
}

// Orders 
.component {
    .row {
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 15px 10px;
      margin-bottom: 20px;
      box-shadow: 0 2px 10px rgba(0,0,0,0.3)
    }
  
    .column {
      flex: 1;
      padding: 0 10px;
    }

    .row.header {
        flex: 1;
        padding: 0 10px;
        font-weight: bold;
        opacity: 0.8;
        box-shadow: none;
    }

    .icon-show-search.anticon svg {
        display: inline-block;
        height: auto;
        width: 24px;
        cursor: 'pointer';
    }

    .icon-show-search.anticon[data-disabled="true"] svg {
        opacity: 0.5;
        cursor: auto;
    }
  
    .status-idle {
      color: gray;
    }
  
    .status-loading {
      color: rgb(63, 63, 255);
    }
  
    .status-completed {
      color: green;
    }
  
    .status-error {
      // color: rgb(63, 63, 255);
      color: rgb(255, 106, 52);
    }
}

//Register

.register-form{
    background-color: #fff;
    border-radius: 10px;
    padding: 15px 25px;
    margin-bottom: 20px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
    max-width: 750px;

    input{
        // margin-bottom: 15px;
    }

    .error-message{
        color: red;
    }
}

//ConfirmAccount

.structure-confirm-accout {
    height: 60vh;
    display: flex;
    justify-content: center;
    align-items: center;
    
    .register-form{
        height: fit-content;
        .ant-form {
            margin-top: 30px;

            button {
                margin-top: 15px;
            }
        }
    }
}

.ant-message-custom-content, .ant-message-notice-content {
    background-color: rgb(247, 247, 247) !important;
}

.ant-message-notice-content {
    border: 1px solid rgb(189, 189, 189);
}

.ant-message-notice {
    text-align: center !important;
    padding-top: 10px !important;
}


.user-logged-info {
    width: 100%;
    text-align: right;
    color: rgb(117, 117, 117);   
    padding-bottom: 20px;
    line-height: 25px;
}